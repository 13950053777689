import React, { useEffect, useRef } from 'react';
import PinCard from './PinCard';
import './MasonryLayout.css';

function MasonryLayout({ truePins, similarPins, onImageLoadError }) {
  const columnCount = Math.floor((window.innerWidth - 40) / 420);
  const leftGridRef = useRef();
  const rightGridRef = useRef();

  useEffect(() => {
    const syncRowHeights = () => {
      const leftRows = leftGridRef.current?.querySelectorAll('.grid-row');
      const rightRows = rightGridRef.current?.querySelectorAll('.grid-row');

      if (!leftRows || !rightRows) return;

      leftRows.forEach((row, index) => {
        const rightRow = rightRows[index];
        if (!rightRow) return;

        const leftHeight = row.getBoundingClientRect().height;
        const rightHeight = rightRow.getBoundingClientRect().height;
        const maxHeight = Math.max(leftHeight, rightHeight);

        row.style.height = `${maxHeight}px`;
        rightRow.style.height = `${maxHeight}px`;
      });
    };

    syncRowHeights();
    window.addEventListener('resize', syncRowHeights);
    return () => window.removeEventListener('resize', syncRowHeights);
  }, [truePins, similarPins]);

  const renderGrid = (pins, ref, isSimilarGrid = false) => (
    <div className="masonry-layout" ref={ref}>
      {Array.from({ length: Math.ceil(pins.length / columnCount) }).map((_, rowIndex) => (
        <div key={`row-${rowIndex}`} className="grid-row">
          {pins.slice(rowIndex * columnCount, (rowIndex + 1) * columnCount).map(pin => {
            let pinToRender = pin;
            if (isSimilarGrid) {
              pinToRender = pin.similarPin;
            }
            return (
              <div key={pinToRender.id} className="pin-wrapper">
                <PinCard
                  pin={pinToRender}
                  onImageLoadError={onImageLoadError}
                  isTrueFeedPin={!isSimilarGrid}
                />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );

  return (
    <div className="split-layout">
      {renderGrid(truePins, leftGridRef)}
      <div className="layout-divider" />
      {renderGrid(similarPins, rightGridRef, true)}
    </div>
  );
}

export default MasonryLayout;