import React, { useState, useEffect, useCallback } from 'react';
import MasonryLayout from './components/MasonryLayout';
import { fetchTrueFeed, fetchSimilarFeed, precomputeSimilarProducts } from './api';
import './App.css';
import placeholderImage from './assets/placeholder.jpg';

function App() {
  const [trueFeedPins, setTrueFeedPins] = useState([]);
  const [similarFeedPins, setSimilarFeedPins] = useState([]);
  const [bookmark, setBookmark] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [failedImageIds, setFailedImageIds] = useState(new Set());
  const [prefetchBookmark, setPrefetchBookmark] = useState(null);
  const [prefetchedPins, setPrefetchedPins] = useState([]);

  const handleImageLoadError = (pinId) => {
    setFailedImageIds(prev => new Set([...prev, pinId]));
  };

  const fetchPins = useCallback(async (isPrefetch = false) => {
    if (isFetching) return;
    setIsFetching(true);

    if (!isPrefetch) {
      setIsLoading(true);
    }

    try {
      const response = await fetchTrueFeed(isPrefetch ? prefetchBookmark : bookmark);
      const newPins = response.pins.filter(pin => !failedImageIds.has(pin.id));

      if (isPrefetch) {
        setPrefetchedPins(newPins);
        setPrefetchBookmark(response.bookmark);
      } else {
        setTrueFeedPins(prevPins => {
          const combinedPins = [...prevPins, ...prefetchedPins, ...newPins];
          const uniquePins = Array.from(new Map(combinedPins.map(pin => [pin.id, pin])).values())
            .filter(pin => !failedImageIds.has(pin.id));

          const newUniquePins = [...prefetchedPins, ...newPins].filter(pin =>
            !prevPins.some(existingPin => existingPin.id === pin.id) &&
            !failedImageIds.has(pin.id)
          );

          if (newUniquePins.length > 0) {
            fetchSimilarFeed(newUniquePins).then(similarPins => {
              setSimilarFeedPins(prev => {
                const newSimilarPins = newUniquePins.map(truePin => {
                  const similarPin = similarPins.find(sp => sp.metadata.source_pin_id === truePin.id);
                  return {
                    truePin,
                    similarPin: similarPin || { id: `${truePin.id}-placeholder`, isPlaceholder: true, image_url: placeholderImage }
                  };
                });

                const allPins = [...prev, ...newSimilarPins];
                return Array.from(new Map(allPins.map(pair => [pair.truePin.id, pair])).values());
              });
            });
          }

          return uniquePins;
        });
        setBookmark(response.bookmark);
        setPrefetchedPins([]);
      }
    } finally {
      setIsLoading(false);
      setIsFetching(false);
    }
  }, [bookmark, prefetchBookmark, failedImageIds, prefetchedPins]);

  const prefetchNextPage = useCallback(async () => {
    if (prefetchBookmark) {
      fetchPins(true);
    }
  }, [prefetchBookmark, fetchPins]);

  useEffect(() => {
    fetchPins();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !isLoading &&
        !isFetching &&
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 500
      ) {
        fetchPins();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, isFetching, fetchPins]);

  useEffect(() => {
    const prefetchInterval = setInterval(() => {
      prefetchNextPage();
    }, 5000); // Prefetch every 5 seconds

    return () => clearInterval(prefetchInterval);
  }, [prefetchNextPage]);

  return (
    <div className="app">
      {isLoading && <div className="loading">Loading...</div>}
      <MasonryLayout
        truePins={trueFeedPins}
        similarPins={similarFeedPins}
        onImageLoadError={handleImageLoadError}
      />
    </div>
  );
}

export default App; 